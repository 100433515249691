import axios from 'axios';
import { apiUrl} from'../Config/Config';

const mem_plan = () => {
    let planLists = [];
    axios.post(apiUrl+"membership/plantypes").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let planlist = res.data.planlist;
            
            for(i; i<planlist.length; i++){
                planLists[i] = { value: planlist[i]['mem_cat_id'], label: planlist[i]['category'] };
            }
            
        }       
    });
    return planLists;
}
const adcategories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"advertisement/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}
const domainList = () => {
    var qs = require('qs');

    let domainlist = [];
    var postobject = {
admin_id : localStorage.getItem('admin_id')

    };

        axios.post(apiUrl+"user/domainlist",qs.stringify(postobject)).then(res => {

        if(res.data.status == "success"){
            let i=0;
            let domainlists = res.data.domainlist;
            
            for(i; i<domainlists.length; i++){
                domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
            }
            
        }       
    });
   
    return domainlist;
}

{/*const categories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const categories1 = () => {
    var qs = require('qs');

    var postobject = {
        type: 'all'
    };

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}*/}
const year_list = () => {

    let years = [];  
    let start_yr = 1950;
    let end_yr = new Date().getFullYear();
    let diff = end_yr - start_yr;
    // console.log(end_yr, 'end_yr');
    // console.log(diff, 'diff');
    let i=0;
    for(i; i<=diff; i++){
        let opt = start_yr + i;
        years[i] = { value: opt, label: opt };
    }
    return years;
}
export const lang = {

    common: {
        title:'Parking 2.0',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        //categories_option: categories(),
       // categories_option1: categories1(),
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Transgender', label: 'Transgender' }
        ],
		/* image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],*/
        image_size_option: [
            { value: '1031x400', label: '1031x400 (W X H)' },
            { value: '250x180', label: '250x180 (W X H)' },
			{ value: '200x150', label: '200x150 (W X H)' },
            { value: '180x120', label: '180x120 (W X H)' }
        ],
        adcategories_option: adcategories(),
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
         field_options :[
            { value: 'bookings', label: 'Bookings' },
            { value: 'purchasedslot', label: 'Purchased Slots' },
            { value: 'soldslots', label: 'Sold Slots' },
            { value: 'archievedslots', label: 'Archieved slots' },
            { value: 'ongoingslots', label: 'On going bookings' },
            
            { value: 'products', label: 'Products' },
            { value: 'home', label: 'Home' },
            { value: 'membership', label: 'Membership' },
            { value: 'schedule', label: 'Schedule' },
            { value: 'users', label: 'Users' },
            { value: 'ratings', label: 'Ratings' },
            { value: 'dispute', label: 'Dispute' },
            
            { value: 'appointments', label: 'Appointments' },
            { value: 'accountmanagement', label: 'Accountmanagement' },
            { value: 'newappointments', label: 'New Appointments' },
            { value: 'acceptedappointments', label: 'Accepted Appointments' },
            { value: 'paidappointments', label: 'Paid Appointments' },
            { value: 'completedslots', label: 'Completed Slots' },
            { value: 'cancelledappointments', label: 'Cancelled Appointments' },
            { value: 'store', label: 'Store' },
            { value: 'recentchat', label: 'Chat' },
            { value: 'transactions', label: 'Transactions'},
            { value: 'Buy', label: 'Buy'},
            { value: 'Sell', label: 'Sell'},
        ],
        discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'standard', label: 'Standard' },
        ],
        pluging_width :[
            { value: '700', label: '700px' },
            { value: '600', label: '600px' }
        ],
        // module_option:[
        //     { value: 'module1', label: 'Module1 (Doctor Listing)' },
        //     { value: 'module2', label: 'Module2 (Product Listing)' },
        // ],
        // productType_option:[
        //     { value: '1', label: 'Normal Product' },
        //     { value: '2', label: 'Shopping Product' },
        //     { value: '3', label: 'Regular Product' },
        // ],
        period_option: [
            // { value: 'Day', label: 'Day' },
            { value: 'Month', label: 'Month' },
            { value: 'Year', label: 'Year' }
        ],
        contentType_option:[
            { value: 'description', label: 'Description' },
            { value: 'form', label: 'Form' },
            { value: 'listing', label: 'Listing' },
            { value: 'membership', label: 'Membership' },
            
        ],
        domainList_option:domainList(),
        mem_plan_option: mem_plan(),
        year_option: year_list(),
        

    },
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
};


